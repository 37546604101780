import React from "react";
import {Row, Col, Divider, Checkbox, Tooltip, Icon, Tag} from "antd";
import Link from "../../../../../systems/routing/Link";
import {CopyToClipboard} from "react-copy-to-clipboard";
import bootbox from "../../../../../modules/common/components/Bootbox/bootbox";
import {translate} from "react-i18next";
import lodash from "lodash";
import {
    DEFAULT_EMPTY_DATA,
    DEFAULT_EMPTY_NUMBER_DATA,
    ORDER_STATUS_WITH_CLASS
} from "../../../../../modules/Order/constants";
import Moment from "react-moment";
import OrderStatus from "./OrderStatus";
import CreateComplaintSeller from "../../../../../modules/Order/ListOrder/components/CreateComplaintSeller";
import OrderPurchaser from "./OrderPurchaser";
import {currentFormatter, dateFormatter} from "../../../../../modules/common/services/format";
import Timer from "../../../../../modules/common/components/Timer";
import moment from "moment";
import {STATE_FILTER_COMPLAINT_DEFAULT} from "../../../../../modules/ComplaintSeller/constants";
import {url} from "../../../../../systems/routing";
import queryString from "query-string";
import PresenceActiveIcon from "@atlaskit/icon/glyph/presence-active";
import SiteIcon from "../../../../../modules/Image/SiteIcon";
import CanExport from "../../../../../modules/PurchaseBuying/OrderDetail/components/CanExport/CanExport";
import logoGobiz from "../../../../../resources/img/gobiz-icon.png";
import OrderStage from './OrderStage'
const CN_IMAGE_LINK = require('../../../../../resources/img/flags-icons/cn.png')

class OrderItem extends React.Component {

    formatData() {
        let {order} = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol", '¥');
        order.time_teller_format = dateFormatter.full(order.time_teller);
        order.time_now = order.time_now ? order.time_now : moment().format('YYYY-MM-DD HH:mm:ss');
        order.time_paid_staff_format = dateFormatter.full(order.time_paid_staff);
        order.order_partner_time_deposit_format = dateFormatter.full(order.order_partner_time_deposit);
        order.complaintSellerTotalFormat = order.complaintSellerTotal > 9 ? "9+" : order.complaintSellerTotal;
        let filterComplaint = {...STATE_FILTER_COMPLAINT_DEFAULT};
        filterComplaint = {...filterComplaint, ...{code: lodash.get(order, 'code', '')}};
        filterComplaint = lodash.omitBy(filterComplaint, value => value === '');
        let urlComplaintByOrder = url.to('complaint-seller') + '?_i=1&';
        order.urlComplaintByOrder = urlComplaintByOrder + queryString.stringify(filterComplaint, {arrayFormat: 'bracket'});
        let invoiceCodes = lodash.get(order, 'invoiceCodes', []);
        order.invoiceCodeDisplay = invoiceCodes.length > 0 ? invoiceCodes[0].invoice_code : "";
        order.countInvoiceCode = invoiceCodes.length;
        order.agency_alias = lodash.get(order, 'agency_alias', '').toUpperCase();
        order.note_buyer_confirm_message = function () {
            return lodash.chain(order.note_buyer_confirm).groupBy("username").map(function (offers, username) {
                let messages = lodash.map(offers, function (item) {
                    if (item.type === 'qty_bought') {
                        item = {
                            ...item, ...{
                                new: currentFormatter.toLocaleStringCurrency(parseFloat(item.new)),
                                old: currentFormatter.toLocaleStringCurrency(parseFloat(item.old))
                            }
                        };
                    }
                    if (item.type === 'unit_price') {
                        item = {
                            ...item, ...{
                                new: currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(item.new), 5),
                                old: currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(item.old), 5)
                            }
                        };
                    }
                    return lodash.omit(item, "username");
                });

                return {
                    username: username,
                    messages: messages,
                    timeAgo: lodash.last(messages).create_time
                };
            }).value();
        }

        let buyingPolicy = lodash.get(order, 'policies.BUYING', null);
        if (buyingPolicy) {
            let timeRemaining = lodash.get(buyingPolicy, 'time_remaining', null);

            if (timeRemaining && timeRemaining.time && buyingPolicy.is_show_policy) {
                order.buying_policy_time = true;
                order.buying_policy_priority = "time-ago--green";
                order.buying_policy_time_remaining = timeRemaining.time_end;
                if (timeRemaining.priority === 'high') {
                    order.buying_policy_priority = 'time-ago--red';
                } else if (timeRemaining.priority === 'medium') {
                    order.buying_policy_priority = 'time-ago--yellow';
                }

            } else if (timeRemaining && !timeRemaining.time && buyingPolicy.is_show_policy) {
                order.buying_policy_time = true;
                order.buying_policy_over_due = true;
            }
        }

        let internalPolicy = lodash.get(order, 'policies.INTERNAL_BUYING', null);
        if (internalPolicy) {
            const timeInternalRemaining = lodash.get(internalPolicy, 'time_remaining', null);
            if (timeInternalRemaining && timeInternalRemaining.time && internalPolicy.is_show_policy) {
                order.internal_policy_time = true;
                order.internal_policy_priority = "time-ago--orange";
                order.internal_policy_time_remaining = timeInternalRemaining.time_end;
            } else if (timeInternalRemaining && !timeInternalRemaining.time && internalPolicy.is_show_policy) {
                order.internal_policy_time = true;
                order.internal_policy_over_due = true;
            }
        }
        return order;
    }

    /**
     * generate class for status block
     */
    _generateStatusClass(orderStatus) {
        return "a-status a-status--" +
            ((orderStatus && ORDER_STATUS_WITH_CLASS[orderStatus]) ?
                ORDER_STATUS_WITH_CLASS[orderStatus].className : "wating-buy");
    }

    handleChange = e => {
        if (e.target.checked)
            this.props.onCheckOrder(e.target.value, true);
        else
            this.props.onCheckOrder(e.target.value);
    }

    render() {
        let {
            t,
            onOrderIsJustAdded,
            ordersIsJustAddedComplaint,
            complaintSellerReasons
        } = this.props;
        let order = this.formatData();

        let logTimeStatusOk = lodash.get(order, "log_time_status_" + order.status, "");
        let grandTotal = lodash.get(order, "grand_total", DEFAULT_EMPTY_NUMBER_DATA);
        let grandTotalVnd = lodash.get(order, "grand_total_converted", DEFAULT_EMPTY_NUMBER_DATA);
        let currencySymbol = lodash.get(order, "currencySymbol", '¥');
        grandTotal = grandTotal ? parseFloat(grandTotal) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotalVnd = grandTotalVnd ? parseFloat(grandTotalVnd) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotal = currentFormatter.toLocaleStringCurrency(grandTotal, 5) + " " + currencySymbol;
        grandTotalVnd = currentFormatter.toLocaleStringCurrencyVND(grandTotalVnd) + " " + t("order:vnd");

        let complaint_seller_active = lodash.get(order, "order_detail.complaint_seller_active", 0);
        let complaint_seller_total = lodash.get(order, "order_detail.complaint_seller_total", 0);
        const taobaoPurchaseOrder = lodash.get(order, "taobao_purchase_order", {});
        return <div className="order-item">
            <Row>
                <Col xs={24} md={9} className="flex flex-start">
                    <Checkbox
                        key={order.id}
                        value={order.id}
                        disabled={order.teller === null ? false : true}
                        onChange={this.handleChange}
                        checked={(lodash.includes(this.props.checkedOrders, order.id) && order.teller === null) ? true : false}
                    />

                    {
                        order.read_comment ?
                            null
                            :
                            (<span className="icon-wait-read-comment"><PresenceActiveIcon/></span>)
                    }

                    <p
                        style={{background: lodash.get(order, "agency.color", "")}}
                        className={(order.read_comment ? "ml-3 " : "") + "a-font--12 header_partner header_partner--" + order.agency_code}
                    >
                        {order.agency_alias}
                    </p>
                    {
                        !!order.is_full_customs_info &&
                        <Tooltip title={t("orderDetail:tooltip.full_customs_info")}>
                            <span className="pl-2">
                                <Icon type="check-circle" theme="twoTone" twoToneColor="#52c41a"/>
                            </span>
                        </Tooltip>
                    }
                    <Link
                        isNewTab={true}
                        className="mr-2 ml-2 a-text--bold"
                        to="purchase.buying.detail"
                        params={{id: order.id}}>
                        {order.code}
                    </Link>

                    <CopyToClipboard
                        text={order.code}
                        onCopy={() => {
                            bootbox.success(t("message.copied") + ": " + order.code)
                        }}>
                        <i className="far fa-copy copy a-text--pointer a-text--blue"/>
                    </CopyToClipboard>

                    <OrderStatus status={order.status}/>

                    <OrderStage stage={order.order_detail.stage} canUsingOrderStage={this.props.canUsingOrderStage} />

                    <span className="ml-2 a-text--fz-12">
                        {logTimeStatusOk === "" ? DEFAULT_EMPTY_DATA :
                            <Moment format="DD/MM HH:mm">{logTimeStatusOk}</Moment>}
                    </span>
                </Col>
                <Col xs={12} md={6}>
                    <div className="d-flex align-item-center">
                        {
                            order.seller_source === "external" ?
                            <img src={logoGobiz}
                                width={"14px"}
                                alt={"icon-gobiz"} />
                            :  <SiteIcon
                            width="14px"
                            src={order.seller_source}
                            alt="avatar_seller"
                        />
                        }
                    
                        <span
                            style={{textTransform: "capitalize"}}
                            className="ml-1 a-text--fz-12">
                            {t(`order:ORDER_SOURCE.${order.seller_source}`)}
                            <CanExport can={lodash.get(order, "order_detail.can_exported", null)} showText={true}
                                    type="order"/>
                        </span>
                    </div>
                    <span>
                        {
                            !lodash.isEmpty(taobaoPurchaseOrder) &&
                            <p className="mr-2" style={{color: "#ff6a08", fontWeight: 500 }}>Đơn TBG <b>#{taobaoPurchaseOrder.purchase_id}{` (${t(`order:ORDER_TAOBAO_STATUS.${taobaoPurchaseOrder.purchase_order_status}`)})`}</b></p>
                        }
                    </span>
                </Col>
                <Col xs={12} md={9} className="a-text--right">
                    {
                        !!order.is_cn_address && 
                        <Tooltip title={t("orderDetail:tooltip.self_transport")}>
                        <img
                            src={CN_IMAGE_LINK}
                            alt="language"
                            style={{
                                width: '25px',
                                marginRight: '10px',
                            }}
                        />
                        </Tooltip>
                    }
                    {
                        !!order.self_negotiate && 
                        <Tooltip title={t("orderDetail:tooltip.self_negotiate")}>
                            <Tag color="#82e910">Deal</Tag>
                        </Tooltip>
                    }
                    {
                        !!order.fintech &&
                        <Tooltip title={t("orderDetail:tooltip.fin_tech")}>
                            <Tag color="#108ee9">Fiin</Tag>
                        </Tooltip>
                    }
                    {
                        complaint_seller_total > 0 &&
                        <span className="mr-2 a-text--fz-12 color-gray">Tổng KNNB: <Tooltip
                            title="Tổng số KNNB đang hoạt động"><strong
                            className="a-text--color-black">{complaint_seller_active}</strong></Tooltip>/<Tooltip
                            title="Tổng số KNNB"><strong
                            className="a-text--color-black">{complaint_seller_total}</strong></Tooltip></span>
                    }
                    {
                        order.buying_policy_time &&
                        <span className={"ml-2 a-text--fz-12 " + order.buying_policy_priority}>
                            <Timer
                                date={order.buying_policy_time_remaining || ''}
                                textBefore={t('order:detail.remaining')}
                                textAfter={t('order:detail.to_buying')}
                                overTimeText={t('order:status.OVERDUE_PURCHASE')}
                                timeBold
                                time_now={order.time_now}
                            />
                        </span>
                    }
                    <CreateComplaintSeller
                        order={order}
                        newScreen={true}
                        onOrderIsJustAdded={onOrderIsJustAdded}
                        ordersIsJustAddedComplaint={ordersIsJustAddedComplaint}
                        complaintSellerReasons={complaintSellerReasons}
                    />
                </Col>
            </Row>
            <Divider className="mt-2 mb-2"/>
            <Row>
                <Col xs={24} md={9}>
                    <div className="d-flex">
                        <Link isnewtab={'true'} to="purchase.buying.detail" params={{id: order.id}}>
                            <img style={{height: "45px", width: "45px"}}
                                 src={order.order_item_image || require('../../../../../resources/img/default-thumbnail.jpg')}
                                 onError={(e) => {
                                     e.target.onerror = null;
                                     e.target.src = require('../../../../../resources/img/u32.png')
                                 }}
                                 alt="Avatar"/>
                        </Link>
                        <div className="ml-2">
                            {
                                this.props.canViewContact &&
                                <p className="a-font--12 mt-1 d-flex">
                                    <span className="a-text--bold">@{order.buyer_username || DEFAULT_EMPTY_DATA}</span>
                                </p>
                            }
                            <div className="d-flex align-items-center">
                                <div className="a-item__account__time-ordering a-font--12">
                                    <span className="a-text--color-black-45">{t('order:list.deposit')} :</span>
                                    {!order.deposited_at ?
                                        DEFAULT_EMPTY_DATA :
                                        <Moment format="HH:mm:ss DD/MM/YYYY">{order.deposited_at}</Moment>}
                                </div>
                                
                                {
                                    order.internal_policy_time &&
                                    <div className={"ml-2 a-text--fz-10 " + order.internal_policy_priority}>
                                        <Timer
                                            date={order.internal_policy_time_remaining || ''}
                                            textBefore={t('order:detail.remaining')}
                                            textAfter={t('order:detail.to_violate_internal_policy')}
                                            overTimeText={t('order:detail.violate_internal_policy')}
                                            overtimeClass="time-ago--orange"
                                            timeBold
                                            time_now={order.time_now}
                                        />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} md={6}>
                    <p className="a-item__block__total a-font--12">
                        {t('order:list.total')}:&nbsp;
                        <span>{order.total_item_qty_bought || DEFAULT_EMPTY_NUMBER_DATA}</span> {t('order:list.product')} /&nbsp;
                        <span>{order.total_item_link || DEFAULT_EMPTY_NUMBER_DATA}</span> {t('order:list.link')}
                    </p>
                    <p className="order-item-total a-item__block__total--money a-font--12">{grandTotalVnd}&nbsp;
                        - <span className="a-text--red">{grandTotal}</span>
                    </p>
                </Col>
                <Col xs={24} md={9}>
                    <div className="d-flex a-font--12">
                        <span className="mr-3">{t("order:list.tradesman")}:</span>
                        <OrderPurchaser
                            order={order}
                            tellers={this.props.tellers}
                            onAssignPurchaser={this.props.onAssignPurchaser}
                            onUnassignPurchaser={this.props.onUnassignPurchaser}
                            distributing={this.props.distributing}
                        />
                    </div>
                    <p className="order-item-ttv a-font--12">
                        {t("order:list.payer")}:
                        <span className="ml-3">{order.paid_staff_name || DEFAULT_EMPTY_DATA}
                            / @{order.paid_staff_username || DEFAULT_EMPTY_DATA} {order.time_paid_staff_format}</span>
                    </p>
                </Col>
            </Row>
        </div>;
    }
}

export default translate()(OrderItem);
