import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Stats from "./Stats";
import {Modal} from "antd";
import PerformAction from "./PerformAction";
import PurchaseList from "./PurchaseList";
import FormSearch from "../FormSearch";
import lodash from "lodash";
import FormPerformAction from "../../../../../modules/Purchase/ListOrder/components/FormPerformAction";
import {router, url} from "../../../../../systems/routing";

class ListPurchases extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            formData: {item_checked: []},
            checkedOrders: [],
            checkedOrderIds: [],
        }
    }

    componentDidMount() {
        if (Array.isArray(this.props.purchaseAddresses) && this.props.purchaseAddresses.length === 0) {
            this.props.getPurchaseAddressList({pagination: 0, status: 1});
        }

        if (Array.isArray(this.props.warehouses) && this.props.warehouses.length === 0) {
            this.props.getListWarehouse({pagination: 0, status: 1});
        }

        let {user} = this.props;
        let address_default = lodash.get(user, 'user.purchaseAddress', []);
        address_default = address_default.map((address_default_item) => {
            return address_default_item.id
        });

        // Nếu không sử dụng cấu hình kho phân phối nhận đơn
        if (!this.props.isDistributiveWarehouseSetting && !this.props.isUsingOrderDistributorSetting) {
            let {formData} = this.state;
            let item_checked = lodash.concat(formData.item_checked, address_default);
            item_checked = lodash.uniq(item_checked);
            this.updateFormData({item_checked: item_checked});
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        let nextState = {...prevState};
        if (Array.isArray(nextProps.warehouses) && nextProps.warehouses.length === 1) {
            nextState.formData = {item_checked: [nextProps.warehouses[0].value]};
        }
        return nextState;
    }

    /**
     * Xu ly khi GDV bam nut nhan don
     *
     * @param formData
     */
    onRequestDistributor(data) {
        let {user} = this.props;
        let params = {...data, ...{id_user: user.user.id}};
        this.props.requestOrdersForBuying(params);
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tích địa chỉ
     *
     * @param event
     */
    onCheckItem(event) {
        let target = event.target;
        let {warehouses} = this.props;
        let value = lodash.toInteger(target.value);
        let checked = target.checked;
        let check_all = false;
        let item_checked = lodash.get(this.state, 'formData.item_checked', []);
        // Nếu bỏ tích chọn
        if (!checked) {
            item_checked = lodash.difference(item_checked, [value]);
        }
        // Nếu checkbox được tích chọn
        if (checked) {
            item_checked = lodash.concat(item_checked, [value]);
            item_checked = lodash.uniq(item_checked);
        }
        if (item_checked.length === warehouses.length) {
            check_all = true;
        }
        this.updateFormData({item_checked: item_checked, check_all: check_all});
    }

    /**
     * Hàm thực hiện đánh dấu chọn hoặc bỏ chọn tất cả
     * NOTE: Hiện tại không dùng hàm này
     * @param event
     */
    onCheckAll(event) {
        let {purchaseAddresses} = this.props;
        let OrderIds = [];
        let checked = false;
        let item_checked = [];
        let check_all = false;
        // Nếu checkbox được tích chọn
        if (checked) {
            OrderIds = purchaseAddresses.map(purchaseAddressItem => {
                return purchaseAddressItem.value;
            });
            OrderIds = lodash.compact(OrderIds);
            check_all = true;
            item_checked = lodash.concat(item_checked, OrderIds);
        }

        this.updateFormData({item_checked: item_checked, check_all: check_all});
    }

    /**
     * Chọn từng đơn để tạo YCTT
     */
    onCheckOrder = (invoice_code, checked) => {
        let {checkedOrders, checkedOrderIds} = this.state;
        let {orders} = this.props;
        const orderInfo = lodash.find(orders, ['code', invoice_code]);

        if (checked) {
            checkedOrders.push(invoice_code);
            checkedOrderIds.push(orderInfo.id);
        }else {
            checkedOrders = lodash.without(checkedOrders, invoice_code);
            checkedOrderIds = lodash.without(checkedOrderIds, orderInfo.id);
        }

        this.setState({checkedOrders, checkedOrderIds})
    }

    /**
     * Chọn tất cả các đơn để tạo YCTT
     * @param checked
     */
    onCheckAllOrders = (checked) => {
        if (checked) {
            let {checkedOrders, checkedOrderIds} = this.state;
            let {orders} = this.props;
            orders.forEach(order => {
                if (!lodash.includes(checkedOrders, order.code)) {
                    checkedOrders.push(order.code);
                    checkedOrderIds.push(order.id);
                }

            });
            this.setState({checkedOrders, checkedOrderIds});
        } else {
            this.clearCheckAll();
        }
    }

    // Clear các đơn đã chọn
    clearCheckAll = () => {
        this.setState({checkedOrders: [], checkedOrderIds: []});
    }

    onCreateRequestPayment = () => {
        let {checkedOrders} = this.state;
        if (checkedOrders.length > 0) {
            router.redirect(url.to('payment-request.create-external', {idOrder: checkedOrders}));
        } else {
            Modal.error({
                title: 'Chưa có đơn hàng nào được chọn',
                content: 'Bạn phải chọn 1 hoặc nhiều đơn hàng trước khi tạo yêu cầu thanh toán',
            });
        }
    }

    /**
     * Cập nhật dữ liệu state
     * @param input
     */
    updateFormData(input) {
        let formData = this.state.formData;
        formData = {...formData, ...input};
        this.setState({formData});
    }

    render() {
        let {
            formData,
            checkedOrders,
            checkedOrderIds
        } = this.state;
        return <div className="a-list mt-5">
            <Stats
                stats={this.props.statsStatus}
                filter={this.props.filter}
                onSearch={this.props.onSearch}
                canChangeWaitingPay={this.props.canChangeWaitingPay}
            />
            <PerformAction
                violateReasons={this.props.violateReasons}
                canCreatePaymentRequestSB={this.props.canCreatePaymentRequestSB}
                onSearch={this.props.onSearch}
                checkedOrders={checkedOrders}
                checkedOrderIds={checkedOrderIds}
                onCheckAllOrders={this.onCheckAllOrders}
                onCreateRequestPayment={this.onCreateRequestPayment}
                orders={this.props.orders}
                filter={this.props.filter}
                pagination={this.props.pagination}
                isDistributiveWarehouseSetting={this.props.isDistributiveWarehouseSetting}
                isUsingOrderDistributorSetting={this.props.isUsingOrderDistributorSetting}
                warehouses={this.props.warehouses}
                onCheckItem={this.onCheckItem.bind(this)}
                onCheckAll={this.onCheckAll.bind(this)}
                item_checked={formData.item_checked}
                onRequestDistributor={this.onRequestDistributor.bind(this)}
                getLoadingRequestOrder={this.props.getLoadingRequestOrder}
                orderDistributorRequireWarehouse={this.props.orderDistributorRequireWarehouse}
                orderDistributorType={this.props.orderDistributorType}
            />
            <PurchaseList
                canUsingOrderStage={this.props.canUsingOrderStage}
                orders={this.props.orders}
                pagination={this.props.pagination}
                checkedOrders={checkedOrders}
                onCheckOrder={this.onCheckOrder}
                onSearch={this.props.onSearch}
                onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                loading={this.props.loading}
                canViewContact={this.props.canViewContact}
            />
        </div>;
    }
}

ListPurchases.propTypes = {};

export default ListPurchases;
