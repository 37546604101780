import React from "react";
import {Row, Col, Divider, Checkbox, Tooltip, Tag} from "antd";
import Link from "../../../../../systems/routing/Link";
import {CopyToClipboard} from "react-copy-to-clipboard";
import bootbox from "../../../../../modules/common/components/Bootbox/bootbox";
import {translate} from "react-i18next";
import lodash from "lodash";
import {DEFAULT_EMPTY_DATA, DEFAULT_EMPTY_NUMBER_DATA} from "../../../../../modules/Order/constants";
import Moment from "react-moment";
import {currentFormatter, dateFormatter} from "../../../../../modules/common/services/format";
import Timer from "../../../../../modules/common/components/Timer";
import moment from "moment";
import {ORDER_STATUS_REAL} from "../../../../../systems/globalContant/actionContant";
import {STATE_FILTER_COMPLAINT_DEFAULT} from "../../../../../modules/ComplaintSeller/constants";
import {url} from "../../../../../systems/routing";
import queryString from "query-string";
import ServicesItem from "./ServicesItem";
import InvoiceCodes from "../../../../../modules/Purchase/ListOrder/components/InvoiceCodes";
import OrderStatus from "../../../../Order/ListOrder/components/ListOrder/OrderStatus";
import NoticeConfirm from "../../../../../modules/Purchase/ListOrder/components/NoticeConfirm";
import PresenceActiveIcon from "@atlaskit/icon/glyph/presence-active";
import SiteIcon from "../../../../../modules/Image/SiteIcon";
import CanExport from "../../../../../modules/PurchaseBuying/OrderDetail/components/CanExport/CanExport";
import OrderStage from '../../../../Order/ListOrder/components/ListOrder/OrderStage'
const CN_IMAGE_LINK = require('../../../../../resources/img/flags-icons/cn.png')

class PurchaseItem extends React.Component {

    formatData() {
        let {order} = this.props;
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        order.total_shipping_fee_inland_text = order.total_shipping_fee_inland ? currentFormatter.toLocaleStringCurrency(parseFloat(order.total_shipping_fee_inland), 10) + currencySymbol : DEFAULT_EMPTY_DATA;
        order.time_now = order.time_now ? order.time_now : moment().format('YYYY-MM-DD HH:mm:ss');
        order.time_teller_format = dateFormatter.full(order.time_teller);
        order.time_paid_staff_format = dateFormatter.full(order.time_paid_staff);
        order.order_partner_time_deposit_format = dateFormatter.shortTime(order.order_partner_time_deposit);

        if (order.status && lodash.get(order, "log_time_status_" + order.status)) {
            order.logTimeStatusCurrent = dateFormatter.shortTime(lodash.get(order, "log_time_status_" + order.status));
        }

        order.services = lodash.get(order, 'services', []).filter((service) => {
            return service.status !== 'INACTIVE';
        });

        // order previous status and time
        order.previous_status_time = '';
        order.previous_status = '';
        let orderStatusArr = lodash.values(ORDER_STATUS_REAL);
        let statusIndexOf = lodash.indexOf(orderStatusArr, order.status);

        if (orderStatusArr[statusIndexOf - 1]) {
            let previousStatus = orderStatusArr[statusIndexOf - 1];
            let logStatusTime = 'log_time_status_' + previousStatus;
            order.previous_status_time = dateFormatter.shortTime(lodash.get(order, logStatusTime));
            order.previous_status = previousStatus;
        }

        order.complaintSellerTotalFormat = order.complaintSellerTotal > 9 ? "9+" : order.complaintSellerTotal;
        let filterComplaint = {...STATE_FILTER_COMPLAINT_DEFAULT};
        filterComplaint = {...filterComplaint, ...{code: lodash.get(order, 'code', '')}};
        filterComplaint = lodash.omitBy(filterComplaint, value => value === '');
        let urlComplaintByOrder = url.to('complaint-seller') + '?_i=1&';
        order.urlComplaintByOrder = urlComplaintByOrder + queryString.stringify(filterComplaint, {arrayFormat: 'bracket'});
        let invoiceCodes = lodash.get(order, 'source_invoice', []);
        order.invoiceCodeDisplay = invoiceCodes.length > 0 ? invoiceCodes[0].invoice_code : "";
        order.countInvoiceCodeDisplay = invoiceCodes.length -1 > 9 ? "9+" : invoiceCodes.length -1;
        order.agency_alias = lodash.get(order, 'agency_alias', '').toUpperCase();
        order.purchase_address_alias = lodash.get(order, 'purchase_address_alias', '---');

        order.note_buyer_confirm_message = function () {
            return lodash.chain(order.note_buyer_confirm).groupBy("username").map(function (offers, username) {
                let messages = lodash.map(offers, function (item) {
                    if (item.type === 'qty_bought') {
                        item = {
                            ...item, ...{
                                new: currentFormatter.toLocaleStringCurrency(parseFloat(item.new)),
                                old: currentFormatter.toLocaleStringCurrency(parseFloat(item.old))
                            }
                        };
                    }
                    if (item.type === 'unit_price') {
                        item = {
                            ...item, ...{
                                new: currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(item.new), 5),
                                old: currencySymbol + currentFormatter.toLocaleStringCurrency(parseFloat(item.old), 5)
                            }
                        };
                    }
                    return lodash.omit(item, "username");
                });

                return {
                    username: username,
                    messages: messages,
                    timeAgo: lodash.last(messages).create_time
                };
            }).value();
        };

        let buyingPolicy = lodash.get(order, 'policies.BUYING', null);
        if (buyingPolicy) {
            let timeRemaining = lodash.get(buyingPolicy, 'time_remaining', null);
            if (timeRemaining && timeRemaining.time && buyingPolicy.is_show_policy) {
                order.buying_policy_priority = "time-ago--green";
                order.buying_policy_time = true;
                order.buying_policy_time_remaining = timeRemaining.time_end;
                if (timeRemaining.priority === 'high') {
                    order.buying_policy_priority = 'time-ago--red';
                } else if (timeRemaining.priority === 'medium') {
                    order.buying_policy_priority = 'time-ago--yellow';
                }

            } else if (timeRemaining && !timeRemaining.time && buyingPolicy.is_show_policy) {
                order.buying_policy_over_due = true;
                order.buying_policy_time = true;
            }
        }

        let internalPolicy = lodash.get(order, 'policies.INTERNAL_BUYING', null);
        if (internalPolicy) {
            const timeInternalRemaining = lodash.get(internalPolicy, 'time_remaining', null);
            if (timeInternalRemaining && timeInternalRemaining.time && internalPolicy.is_show_policy) {
                order.internal_policy_time = true;
                order.internal_policy_priority = "time-ago--orange";
                order.internal_policy_time_remaining = timeInternalRemaining.time_end;
            } else if (timeInternalRemaining && !timeInternalRemaining.time && internalPolicy.is_show_policy) {
                order.internal_policy_time = true;
                order.internal_policy_over_due = true;
            }
        }


        return order;
    }

    handleChange = e => {
        this.props.onCheckOrder(e.target.value, e.target.checked);
    }

    render() {
        let {t} = this.props;
        let order = this.formatData();
        let grandTotal = lodash.get(order, "grand_total", DEFAULT_EMPTY_NUMBER_DATA);
        let grandTotalVnd = lodash.get(order, "grand_total_converted", DEFAULT_EMPTY_NUMBER_DATA);
        let currencySymbol = lodash.get(order, "currencySymbol",  '¥');
        grandTotalVnd = grandTotalVnd ? parseFloat(grandTotalVnd) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotal = grandTotal ? parseFloat(grandTotal) : DEFAULT_EMPTY_NUMBER_DATA;
        grandTotal = currentFormatter.toLocaleStringCurrency(grandTotal, 5) + " " + currencySymbol;
        grandTotalVnd = currentFormatter.toLocaleStringCurrencyVND(grandTotalVnd);
        grandTotalVnd += ' ' + t("order:vnd");
        let updateQuantity = order.note_buyer_confirm_message();
        let account_purchaser = lodash.get(order, "account_purchaser", null);
        const taobaoPurchaseOrder = lodash.get(order, "taobao_purchase_order", {});
        const self_negotiate = lodash.get(order, "self_negotiate", false)
        return (
            <div className="order-item">
                <Row>
                    <Col span={6} className="flex flex-start">
                        <Checkbox
                            key={order.id}
                            value={order.code}
                            onChange={this.handleChange}
                            checked={lodash.includes(this.props.checkedOrders, order.code) ? true : false}
                        />
                        {
                            order.read_comment ? null :
                                <span className="icon-wait-read-comment"><PresenceActiveIcon/></span>
                        }
                        <p
                            style={{ background: lodash.get(order, "agency.color", "") }}
                            className={(order.read_comment ? "ml-3 " : "") + "a-font--12 header_partner header_partner--" + order.agency_code}
                        >
                            {order.agency_alias}
                        </p>

                        <Link
                            isNewTab={true}
                            className="mr-2 ml-2 a-text--bold"
                            to="purchase.buying.detail"
                            params={{id: order.id}}>
                            {order.code}
                        </Link>

                        <CopyToClipboard
                            text={order.code}
                            onCopy={() => { bootbox.success(t("message.copied") + ": " + order.code) }}>
                            <i className="far fa-copy copy a-text--pointer a-text--blue mr-2" />
                        </CopyToClipboard>

                        {/*<OrderStatus status={order.status} />*/}

                        <OrderStage stage={order.order_detail.stage} canUsingOrderStage={this.props.canUsingOrderStage} />
                    </Col>
                    <Col span={6}>
                        <SiteIcon
                            width="14px"
                            src={order.seller_source}
                            alt="avatar_seller"
                        />
                        <span style={{textTransform: "capitalize"}} className="ml-1 a-text--fz-12">
                            {t(`order:ORDER_SOURCE.${order.seller_source}`)}
                            <CanExport can={lodash.get(order, "order_detail.can_exported", null)} showText={true} type="order" />
                        </span>
                        <span>
                        {
                            !lodash.isEmpty(taobaoPurchaseOrder) &&
                            <p className="mr-2" style={{color: "#ff6a08", fontWeight: 500 }}>Đơn TBG <b>#{taobaoPurchaseOrder.purchase_id}{` (${t(`order:ORDER_TAOBAO_STATUS.${taobaoPurchaseOrder.purchase_order_status}`)})`}</b></p>
                        }
                    </span>
                    </Col>
                    <Col span={6}>
                        <p title={t('order:list.warehouse_code')} style={{color: '#333333'}} className="a-text--fz-12">
                            <span className="a-text--color-black-45">Kho:</span> {order.destination_warehouse ? order.destination_warehouse: DEFAULT_EMPTY_DATA}/{order.purchase_address_alias}
                        </p>
                    </Col>
                    <Col span={6} className="a-text--right d-flex align-item-center justify-content-between">
                        <ServicesItem
                            services={order.services}
                            account_purchaser={account_purchaser}
                        />
                        <div>
                        {
                            !!order.is_cn_address && 
                            <Tooltip title={t("orderDetail:tooltip.self_transport")}>
                                <img
                                    src={CN_IMAGE_LINK}
                                    alt="language"
                                    style={{
                                        width: '25px',
                                        marginRight: '10px',
                                    }}
                                />
                            </Tooltip>
                        }
                        {
                            !!self_negotiate && 
                            <Tooltip title={t("orderDetail:tooltip.self_negotiate")}>
                                <Tag 
                                    color="#82e910" 
                                    style={{
                                        marginRight: '10px',
                                    }}
                                >
                                    Deal
                                </Tag>
                            </Tooltip>
                        }
                        {
                            !!order.fintech &&
                            <Tooltip title={t("orderDetail:tooltip.fin_tech")}>
                                <Tag color="#108ee9">Fiin</Tag>
                            </Tooltip>
                        }
                        </div>
                    </Col>
                </Row>
                <Divider className="mt-2 mb-2" />
                <Row>
                    <Col span={6}>
                        <div className="d-flex">
                            <Link isNewTab={true} to="purchase.buying.detail" params={{id: order.id}}>
                                <img style={{height: "45px", width: "45px"}} src={order.order_item_image || require('../../../../../resources/img/default-thumbnail.jpg')}
                                     onError={(e) => {
                                         e.target.onerror = null;
                                         e.target.src = require('../../../../../resources/img/default-thumbnail.jpg')
                                     }}
                                     alt="Avatar"/>
                            </Link>
                            <div className="ml-2">
                                {
                                    this.props.canViewContact &&
                                    <p className="a-font--12 mt-1 d-flex">
                                        <span className="a-text--bold">@{order.buyer_username || DEFAULT_EMPTY_DATA}</span>
                                        { order.buying_policy_time &&
                                        <span className={"ml-2 a-text--fz-12 a-item__bottom__left " + order.buying_policy_priority}>
                                            <Timer
                                                date={order.buying_policy_time_remaining || ''}
                                                textBefore={t('order:detail.remaining')}
                                                textAfter={t('order:detail.to_buying')}
                                                overTimeText={t('order:status.OVERDUE_PURCHASE')}
                                                timeBold
                                                time_now={order.time_now}
                                            />
                                        </span>
                                        }
                                    </p>
                                }
                                <div className="d-flex align-items-center">
                                    <div className="a-item__account__time-ordering a-font--12">
                                        {/*<span className="a-text--color-black-45">{t('order:list.deposit')} :</span>*/}
                                        {!order.deposited_at ?
                                            DEFAULT_EMPTY_DATA :
                                            <Moment format="HH:mm:ss DD/MM/YYYY">{order.deposited_at}</Moment>}
                                    </div>
                                    
                                    {
                                        order.internal_policy_time &&
                                        <div className={"ml-2 a-text--fz-10 " + order.internal_policy_priority}>
                                            <Timer
                                                date={order.internal_policy_time_remaining || ''}
                                                textBefore={t('order:detail.remaining')}
                                                textAfter={t('order:detail.to_violate_internal_policy')}
                                                overTimeText={t('order:detail.violate_internal_policy')}
                                                timeBold
                                                overtimeClass="time-ago--orange"
                                                time_now={order.time_now}
                                            />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col span={6}>
                        <div className="a-text--fz-12 new_more__codebill">
                            <InvoiceCodes
                                countInvoiceCodeDisplay={order.countInvoiceCodeDisplay}
                                invoiceCodes={order.source_invoice}
                            />
                        </div>
                    </Col>
                    <Col span={6}>
                        <p className="a-font--12">
                            <span className="a-text--color-black-45">{t('order:list.total')}:</span>&nbsp;
                            <strong>{order.total_item_qty_bought || DEFAULT_EMPTY_NUMBER_DATA}</strong>&nbsp;
                            <span className="a-text--color-black-45">{t('order:list.product')}</span> /&nbsp;
                            <strong>{order.total_item_link || DEFAULT_EMPTY_NUMBER_DATA}</strong>&nbsp;
                            <span className="a-text--color-black-45">{t('order:list.link')}</span>
                        </p>
                        <p className="a-item__block__total--money a-font--12 mt-1">{grandTotalVnd}&nbsp;
                            - <span className="a-text--red">{grandTotal}</span>
                            { order.free_ship || order.total_shipping_fee_inland ?
                                (<span className="a-text--blue a-font--11 ml-1"
                                       title={t('order_detail.total_shipping_fee_inland')}
                                       style={{color: '#0052CC', marginLeft: '0px'}}>
                                            ({order.free_ship ? t('orderDetail:order_detail.free') : order.total_shipping_fee_inland_text})
                                </span>) :
                                null
                            }
                        </p>
                        { order.complaintSellerTotal !== 0 ?
                            <div className="a-item__block__is_complaintseller mt-3 mb-1 a-font--12">
                                <div className="a-item__block__complaintseller mr-5"
                                     onClick={() => window.open(order.urlComplaintByOrder, "_blank")}
                                >
                                    <Link>{t('order:list.KNNB')}</Link>
                                    <span>{order.complaintSellerTotalFormat}</span>
                                </div>
                            </div> : null
                        }
                    </Col>
                    <Col span={6}>
                        <p className="a-font--12" title={t('orderDetail:order_detail.account_buyer')}>
                            <span className="a-text--color-black-45">Tài khoản:</span> {order.account_purchaser_account ? '@' + order.account_purchaser_account : DEFAULT_EMPTY_DATA}
                        </p>
                        { order.previous_status &&
                            <p className="a-font--12 hidden-md mt-1">
                                <span className="a-text--color-black-45">{t("order:status." + order.status)}:</span> {order.logTimeStatusCurrent ? order.logTimeStatusCurrent : DEFAULT_EMPTY_DATA}
                            </p>
                        }
                    </Col>
                </Row>
                {
                    order.buyer_confirm && updateQuantity.length ?
                        <Row>
                            <Col span={24}>
                                <NoticeConfirm
                                    order={order}
                                    updateQuantity={order.note_buyer_confirm_message()}
                                    onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                                />
                            </Col>
                        </Row> : null
                }
            </div>
        );
    }
}

export default translate()(PurchaseItem);
