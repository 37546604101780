import React from "react";
import {translate} from "react-i18next";

class OrderStage extends React.Component {
    render() {
        let {stage, canUsingOrderStage, t} = this.props;
        return canUsingOrderStage && stage !=='UNDEFINED' && <span className={`a-text--fz-12 px-3 ml-2 a-stage a-status--is-new a-status--${stage}`}>
            {t(`stage.${stage}`)}
        </span>;
    }
}

export default translate()(OrderStage);