
export const STATUS_LIST = [
	"BULIDING",
	"WAIT_BUYER_P",
	"WAIT_SELLER_SEND_GOODS",
	"WAIT_BUYER_CONFIRM_GOODS",
	"TRADE_CLOSED",
];

export const STAGE_LIST = [
	"UNVERIFIED",
	"VERIFIED",
	"VERIFY_FAILED",
	"NEED_CONFIRM",
	"WAIT_TO_CONFIRM",
	"READY_TO_PAI",
	"READY_TO_BARGAIN",
	"BARGAINING",
	"READ_TO_PAYMENT",
	"SENT_TO_PAYMENT"
];

export const URL_DISTRIBUTOR = "https://distributor.taobao.global/apps/order/list";

export const EVENTS = {
	OPEN_LINK_DISTRIBUTOR: "OPEN_LINK_DISTRIBUTOR"
}
