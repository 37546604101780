import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Icon} from 'antd';
import lodash from 'lodash';
import OrderItem from "./OrderItem";
import Pagination from "../../../../../modules/common/components/Pagination";

class ListOrders extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ordersIsJustAddedComplaint: [] // Danh sách id đơn vừa được thêm khiếu nại
        };
    }

    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
    }

    onOrderIsJustAdded = (order_id) => {
        let {ordersIsJustAddedComplaint} = this.state;
        ordersIsJustAddedComplaint.push(order_id);
        this.setState({ordersIsJustAddedComplaint});
    }

    render() {
        let {orders, tellers, pagination} = this.props;

        return <div className="border-top">
            {
                this.props.loading ?
                    <div className="text-center mt-2 mb-2"><Icon type="loading" /></div> :
                    <>
                        <div>
                            { orders && orders.map((order, index) => {
                                    return <OrderItem
                                        key={index}
                                        order={order}
                                        tellers={tellers}
                                        canUsingOrderStage={this.props.canUsingOrderStage}
                                        checkedOrders={this.props.checkedOrders}
                                        onCheckOrder={this.props.onCheckOrder}
                                        onAssignPurchaser={this.props.onAssignPurchaser}
                                        onUnassignPurchaser={this.props.onUnassignPurchaser}
                                        distributing={this.props.distributing}
                                        onOrderIsJustAdded={this.onOrderIsJustAdded}
                                        ordersIsJustAddedComplaint={this.state.ordersIsJustAddedComplaint}
                                        complaintSellerReasons={this.props.complaintSellerReasons}
                                        canViewContact={this.props.canViewContact}
                                    />
                                })
                            }
                        </div>
                        <div className="a-group--item a_navigation mt-4 mb-4 ml-4 mr-4 d-flex ">
                            <Pagination
                                value={parseInt(pagination.current_page, 10)}
                                total={pagination.page_total}
                                onChange={page => this.onChangePage(page)}
                            />
                        </div>
                        { orders.length === 0 &&
                            <p className="text-center mb-5">Không tìm thấy bản ghi nào</p>
                        }
                    </>
            }
            </div>;
    }
}

ListOrders.defaultProps = {
    orders: []
}

ListOrders.propTypes = {
    orders: PropTypes.array.isRequired
};

export default ListOrders;
