import React from 'react';
import PropTypes from 'prop-types';
import {Row, Col, Icon} from 'antd';
import lodash from 'lodash';
import PurchaseItem from "./PurchaseItem";
import Pagination from "../../../../../modules/common/components/Pagination";

class PurchaseList extends React.Component {

    constructor(props) {
        super(props);
    }

    onChangePage(page) {
        let {filter} = this.props;
        window.scrollTo(0, 0);
        this.setState({page: page});
        this.props.onSearch({...filter, page: page});
    }

    render() {
        let {orders, pagination} = this.props;
        return <div className="border-top mb-5 pb-2">
            {
                this.props.loading ? <div className="text-center mt-2 mb-2"><Icon type="loading" /></div> :
                <>
                    {
                        orders && orders.map((order, index) => {
                            return <PurchaseItem
                                key={index}
                                order={order}
                                onConfirmCustomerOrderBuying={this.props.onConfirmCustomerOrderBuying}
                                checkedOrders={this.props.checkedOrders}
                                onCheckOrder={this.props.onCheckOrder}
                                canViewContact={this.props.canViewContact}
                                canUsingOrderStage={this.props.canUsingOrderStage}
                            />
                        })
                    }
                    <div className="a-group--item a_navigation mt-4 mb-4 ml-4 mr-4 d-flex ">
                        <Pagination
                            value={parseInt(pagination.current_page, 10)}
                            total={pagination.page_total}
                            onChange={page => this.onChangePage(page)}
                        />
                    </div>
                    {
                        orders.length === 0 &&
                        <p className="text-center mb-5">Không tìm thấy dữ liệu</p>
                    }
                </>
            }
            </div>;
    }
}

PurchaseList.defaultProps = {
    orders: []
}

PurchaseList.propTypes = {
    orders: PropTypes.array.isRequired
};

export default PurchaseList;
